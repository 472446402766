<template>
  <div class="meeting">
    <div
      class="image-wrapper"
      v-if="previewScreen && jitsiState === 'closed'">
      <img
        class="previewScreen"
        :src="previewScreen"
        @click.prevent="rejoin"
      />
    </div>
  </div>
</template>

<script>
  import JitsiMeetExternalAPI from '../../external/jitsiExternalApi'

  export default {
    props: [
      'category',
      'breadcrumb',
    ],
    computed: {
      meetingRoom() {
        return this.category?.meetingRoom + this.category?.id
      },
      previewScreen() {
        let media = this.category?.media?.links?.find(l => l.rel === 'download')?.href
        if (!media) {
          media = this.category?.media?.links?.find(l => l.rel === 'preview')?.href
        }
        return media
      }
    },
    data() {
      return {
        jitsiApi: null,
        jitsiState: null,
      }
    },
    mounted() {
      this.initializeJitsiAPI()
    },
    methods: {
      initializeJitsiAPI() {
        let rootElement = document.querySelector('.meeting')
        let rootHeight = document.querySelector('.navigation-content').clientHeight

        this.jitsiState = 'running'
        this.jitsiApi = new JitsiMeetExternalAPI(this.category.meetingServer, {
          noSSL: false,
          height: rootHeight,
          parentNode: rootElement,
          roomName: this.meetingRoom,
          jwt: this.category.meetingJwt,
          userInfo: {
            displayName: this.$store.getters['auth/username'],
            email: this.$store.getters['auth/email'],
          },
          configOverwrite: {
            disableRemoteMute: true,
          }
        })
        // this.jitsiApi.addEventListeners({
        //   videoConferenceJoined: this.videoConferenceJoined
        // })
        this.jitsiApi.on('passwordRequired', () => {
          this.jitsiApi.executeCommand('password', this.category.meetingPwd)
        })
        this.jitsiApi.on('readyToClose', () => {
          this.jitsiState = 'closed'
          rootElement.querySelector('iframe').remove()
        })
      },
      rejoin() {
        this.initializeJitsiAPI()
      }
    },
    inject: [
      '$helper'
    ],
  }
</script>

<style lang="scss" scoped>
  .meeting {
    @apply
    h-full
    w-full
    max-w-full
    max-h-full;

    .image-wrapper {
      @apply
      flex
      w-full
      h-full
      bg-white
      items-center
      justify-center;
    }

    .previewScreen {
      @apply
      max-w-full
      max-h-full
      cursor-pointer;
    }
  }
</style>